import * as React from "react"
import FullScreenBackground from "../components/FullScreenBackground"
import Header from "./Header"
import Footer from "./Footer"
// import ComingSoon from "../pages/coming-soon"
import defaultBg from "../images/bg2.png"

const Layout = ({ children, bg = defaultBg, bgAlt, ...props }) => {
  // const [key, setKey] = React.useState(true)

  // React.useEffect(() => {
  //   setKey(localStorage.getItem("verified"))
  // }, [])

  // if (Boolean(key) === false) {
  //   return (
  //     <ComingSoon
  //       unlock={(val) => {
  //         if (val === "fab2021$") {
  //           if (localStorage) {
  //             const now = Date.now()
  //             localStorage.setItem("verified", now)
  //             setKey(now)
  //             return Promise.resolve(true)
  //           }
  //         }
  //         return Promise.resolve(false)
  //       }}
  //     />
  //   )
  // }
  return (
    <div id="page">
      <FullScreenBackground src={bg} alt={bgAlt} />
      <Header {...props} />
      <div className="content">{children}</div>
      <Footer {...props} />
    </div>
  )
}

export default Layout
