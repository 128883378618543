import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "../styles/components/reel.scss"
import { navigate } from "gatsby"

const slugReplace = (slug) => {
  if (slug.substr("fabric_base")) return slug.replace("fabric_base", "base")
  if (slug.substr("fabric_type")) return slug.replace("fabric_type", "type")
  return slug
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 765 },
    items: 4,
  },
  mobileLarge: {
    breakpoint: { max: 765, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
}

const CarouselItem = ({ name, img, slug }) => (
  <div
    role="button"
    onClick={() => (slug ? navigate(slugReplace(slug)) : null)}
    onKeyDown={() => (slug ? navigate(slugReplace(slug)) : null)}
    className="image-container increase-size"
    tabIndex={0}
  >
    <div className="image-container-text">
      <span>{name}</span>
    </div>
    <img
      alt={name}
      draggable={false}
      style={{ width: "100%", cursor: "pointer" }}
      src={img}
    />
  </div>
)

class Reel extends React.Component {
  state = { additionalTransfrom: 0 }
  render() {
    if (this.props.showAsTiles === true) {
      return (
        <div className="reel">
          <ul className="tiles">
            {this.props.items.map((i, idx) => (
              <li key={idx}>
                <CarouselItem {...i} />
              </li>
            ))}
          </ul>
        </div>
      )
    }
    return (
      <div className="reel">
        <Carousel
          ssr={false}
          ref={(el) => (this.Carousel = el)}
          partialVisbile={false}
          itemClass="slider-image-item"
          responsive={responsive}
          containerClass="carousel-container-with-scrollbar"
          additionalTransfrom={-this.state.additionalTransfrom}
          beforeChange={(nextSlide) => {
            if (nextSlide !== 0 && this.state.additionalTransfrom !== 150) {
              this.setState({ additionalTransfrom: 150 })
            }
            if (nextSlide === 0 && this.state.additionalTransfrom === 150) {
              this.setState({ additionalTransfrom: 0 })
            }
          }}
        >
          {this.props.items.map((i, idx) => (
            <CarouselItem key={idx} {...i} />
          ))}
        </Carousel>
      </div>
    )
  }
}

export default Reel
export { slugReplace }
