import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import "../styles/layout/header.scss"

const Header = () => {
  const [mobileClass, setMobileClass] = React.useState("hide")
  const [headerBackground, setHeaderBackground] = React.useState("")

  const listenScrollEvent = (e) => {
    if (window.scrollY > 50) {
      setHeaderBackground("white-bg")
    } else {
      setHeaderBackground("transparent-bg")
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)
  }, [])

  const Li = ({ children }) => (
    <li>
      <span
        tabIndex={0}
        role="button"
        onClick={() => setMobileClass("hide")}
        onKeyDown={() => setMobileClass("hide")}
      >
        {children}
      </span>
    </li>
  )

  return (
    <div id="header" className={headerBackground}>
      <div className="container">
        <div className="mobile-header">
          <p className="fabricsthan">
            <Link to="/">Fabricsthan</Link>
          </p>
          <button
            id="mobile-toggle-btn"
            onClick={() => setMobileClass((i) => (i === "hide" ? "" : "hide"))}
          >
            {mobileClass === "hide" ? (
              <FontAwesomeIcon icon={faBars} />
            ) : (
              <FontAwesomeIcon icon={faTimes} />
            )}
          </button>
        </div>
        <ul className={mobileClass}>
          <Li>
            <a href="/#about">About</a>
          </Li>
          <Li>
            <a href="/#fabric-types">Fabric Types</a>
          </Li>
          <li className="fabricsthan">
            <Link to="/">Fabricsthan</Link>
          </li>
          <Li>
            <Link to="/fabrics-of-india">Fabrics Of India</Link>
          </Li>
          <Li>
            <a href="/#contact">Contact</a>
          </Li>
        </ul>
      </div>
    </div>
  )
}

export default Header
