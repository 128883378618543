import * as React from "react"
import "../styles/layout/footer.scss"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopyright } from "@fortawesome/free-solid-svg-icons"

const Copyright = () => (
  <div id="copyright">
    <p>
      <span>
        Fabrcisthan <FontAwesomeIcon icon={faCopyright} />| 2021
      </span>
      <span id="we3solutions">
        Designed and Developed by{" "}
        <a href="https://we3solutions.ca">we3solutions</a>
      </span>
    </p>
  </div>
)

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <ul>
          <li>
            <a href="/#about">About</a>
          </li>
          <li>
            <a href="/#fabric-types">Fabric Types</a>
          </li>
          <li>
            <Link to="/fabrics-of-india">Fabrics Of India</Link>
          </li>
          <li>
            <a href="/#contact">Contact</a>
          </li>
        </ul>
        <ul>{/* Social Icons */}</ul>
        <Copyright />
      </div>
    </footer>
  )
}

export default Footer
